import React, { useEffect, useState } from "react";
import "./top-ups.css";
import { useSelector } from "react-redux";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { Topup } from "../../api/modules/loothoot.class";
import Loading from "../../components/Loader/Loading";
import TopUpModalDeposit from "../../components/TopupModal/TopUpDeposit";

const MyAccount = () => {
  const dispatch = useDispatch();
  const userid = useSelector((state) => state?.auth?.user?.id);
  const [topup, setTopup] = useState();
  const [loading, setLoading] = useState(true);
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [deposit, setDeposit] = useState();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    try {
      const api = () => {
        const res = Topup();
        res
          .then((result) => {
            console.log(result);
            setTopup(result?.data?.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log("err", err);
          });
      };
      api();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handlePay = (item) => {
    setDeposit(item);
    setShow(true);
  };
  return (
    <>
      <MyaccountList />

      <div className="loader-icon">{loading && <Loading />}</div>
      <div className=" topup-table">
        <table className={isDarkMode ? "table table-dark" : "table"}>
          <thead>
            <tr className="tablerow">
              <th>TRANSACTION ID</th>
              <th>CREATED</th>
              <th>AMOUNT</th>
              <th>CURRENCY</th>
              <th>STATUS</th>
              <th>PAY</th>
            </tr>
          </thead>

          <tbody>
            {topup?.map((item, index) => (
              <tr scope="row" className="tablerow" key={index}>
                <td className="tablehead">{item?.transaction_id}</td>
                <td className="tablehead">{item?.created_at}</td>
                <td className="tablehead">{item?.transaction?.pay_amount}</td>
                <td className="tablehead">
                  {item?.transaction?.pay_currency?.toUpperCase()}
                </td>
                <td className="tablehead">
                  {item?.transaction?.payment_status}
                </td>
                <td className="tablehead" style={{ minWidth: "120px" }}>
                  <button
                    className="myaccount-menu-btn"
                    onClick={() => handlePay(item)}
                  >
                    <span>Pay Now</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {deposit && (
        <TopUpModalDeposit
          deposit={deposit}
          show={show}
          setTopUpModalShow={setShow}
        />
      )}

      <div className="logout-button-section mt-5">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
