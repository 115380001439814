import React, { useState, useRef } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import "./document.css";
import Dashboard from "../../components/myaccountdashboard/Dashboard";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { useEffect } from "react";
import { Document, GetDocument } from "../../api/modules/loothoot.class";
import Toaster from "../../components/Toast/Toast";
import { MdVerified } from "react-icons/md";
import { GoUnverified } from "react-icons/go";
const MyAccount = () => {
  const user_id = useSelector((state) => state?.auth?.user?.id);
  const [error, setError] = useState({ success: "", error: "" });
  const [selectedDocument, setSelectedDocument] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState();
  const [succes, setSucces] = useState(true);
  const [document, setDocument] = useState();
  const inputRef = useRef(null);

  const handleDocumentChange = (event) => {
    setSelectedDocument(event.target.value);
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setSucces(false);
    const file = event.target.files[0];
    setName(file?.name);
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("user_document", file);
    formData.append("document_type", selectedDocument);
    try {
      const res = await Document(formData);
      setSucces(res?.data?.success);

      setError((prev) => ({
        ...prev,
        success: res?.data?.message || "Document Uploaded Successfully",
        error: res?.data?.error?.message || "",
      }));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    try {
      const api = async () => {
        const resp = await GetDocument();
        setDocument(resp?.data?.data);
      };
      api();
    } catch (error) {
      console.log(error);
    }
  }, []);
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div>
        <Toaster message={error} />
        {/* <Dashboard /> */}
        <MyaccountList />
        <Container className="mt-5 mb-5">
          <Row>
            <Col md={3}>
              <Form>
                <Form.Group controlId="document">
                  <Form.Label
                    style={{
                      color: "8e8e89",
                      marginBottom: "0px",
                      textTransform: "uppercase",
                      fontSize: "12px",
                      color: "#8e8e99",
                    }}
                  >
                    Document Type*
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleDocumentChange}
                    style={{
                      border: "none",
                      borderRadius: " 0px",
                      boxShadow: "none",
                      padding: "0px 11px",
                      cursor: "pointer",
                      width: "180px",
                      height: "40px",
                    }}
                  >
                    <option value="">Select Document</option>
                    <option value="identity">Identity</option>
                    <option value="residence">Residence</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
        {selectedDocument && (
          <Form.Group
            controlId="file"
            className={
              isDarkMode
                ? " documents-form-grp-dark documents-form-grp mx-2"
                : "documents-form-grp-light documents-form-grp mx-2"
            }
          >
            <Form.Label>
              {succes ? "Drop File(s) Here to Upload" : name}
            </Form.Label>
            <div className="d-flex flex-column align-items-center mb-3">
              <Button
                style={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  border: "none",
                  borderRadius: "0px",
                  backgroundColor: "#759FFA",
                  cursor: "pointer",
                  fontWeight: "500",
                  borderRadius: "30px",
                }}
                className="btn  mb-2"
                onClick={handleButtonClick}
              >
                {succes ? "Select File(s) on Your Computer" : "Uploading..."}
              </Button>
              <span className="text-center">
                {selectedFile && `Selected File: ${selectedFile.name}`}
              </span>
            </div>
            <input
              type="file"
              onChange={handleFileChange}
              ref={inputRef}
              style={{ display: "none" }}
            />
            <div>
              * Maximum file size: 10MB, Allowed File Types: .jpg, .jpeg, .png,
              .doc, .docx, .pdf
            </div>
          </Form.Group>
        )}
      </div>

      <div className="topup-table">
        <table className={isDarkMode ? "table table-dark" : "table"}>
          <thead>
            <tr className="tablerow">
              <th>document_type</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {document?.map((item, index) => (
              <tr scope="row" className="tablerow" key={index}>
                <td className="tablehead">{item?.document_type}</td>
                <td className="tablehead">
                  {item?.document_status == 0 ? (
                    <GoUnverified />
                  ) : (
                    <MdVerified />
                  )}
                  {item?.document_status == 0 ? "unverified" : "Verified"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="logout-button-section">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
