import Modal from "react-bootstrap/Modal";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import "./walletmodal.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import DepositTab from "../deposit/DepositTab";
import WithdrawTab from "../withdraw/WithdrawTab";
import { getCoins } from "../../../../api/modules/loothoot.class";

const presetCoins = [
  {
    img: "https://static.ancientgaming.io/icons/bitcoin.svg",
    title: "BITCOIN",
    coin: "btc",
  },
  {
    img: "https://static.ancientgaming.io/icons/ethereum.svg",
    title: "ETHEREUM",
    coin: "eth",
  },
  {
    img: "https://static.ancientgaming.io/icons/litecoin.svg",
    title: "LTC",
    coin: "ltc",
  },
  {
    img: "https://static.ancientgaming.io/icons/usdt.svg",
    title: "USDT",
    coin: "usdt_erc20",
  },
  {
    img: "https://static.ancientgaming.io/images/BNB.png",
    title: "BNB",
    coin: "bnb_bsc",
  },
  {
    img: "https://static.ancientgaming.io/images/trx.png",
    title: "TRX",
    coin: "trx",
  },
];

const WalletModal = (props) => {
  const activeTab = useSelector((state) => state.account?.activeTab);
  const [key, setKey] = useState(activeTab); // Initialize key based on Redux state

  useEffect(() => {
    setKey(activeTab); // Update key if the activeTab value changes in Redux
  }, [activeTab]);

  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [coin, setCoin] = useState([]);

  useMemo(() => {
    try {
      const api = async () => {
        const response = await getCoins();
        setCoin(response?.data?.data);

        // for (let i = 0; i < presetCoins?.length; i++) {
        //   for (let j = 0; j < response?.data?.data?.data?.list?.length; j++) {
        //     if (
        //       presetCoins[i]?.coin == response?.data?.data?.data?.list[j]?.coin
        //     ) {
        //       const isDuplicate = matchedObjects.some(
        //         (obj) => obj.coin == presetCoins[i]?.coin
        //       );

        //       if (!isDuplicate) {
        //         matchedObjects.push(presetCoins[i]);
        //       }
        //     }
        //   }
        // }
      };
      api();
    } catch (err) {
      console.log(err);
    }
  }, [props.modalShow]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      className={isDarkMode ? "modal-content-dark" : "modal-content-light"}
    >
      <Modal.Header className="wallet-modal-head">
        <div className="wallet-tabs-main">
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            transition={false}
            id="noanim-tab"
            bsPrefix={
              isDarkMode
                ? "mb-3 WalletTabsMain WalletTabsMain-dark"
                : "WalletTabsMain-light mb-3 WalletTabsMain"
            }
          >
            <Tab
              tabClassName={
                isDarkMode && key == "deposit"
                  ? "WalletTabsActive-dark"
                  : key == "deposit" && "WalletTabsActive-light"
              }
              eventKey="deposit"
              title={<span>DEPOSIT</span>}
            >
              {/* {coin?.length > 0 ? */}
              <DepositTab coin={coin} />
              {/* : <></>} */}
            </Tab>
            <Tab
              tabClassName={
                isDarkMode && key == "withdraw"
                  ? "WalletTabsActive-dark"
                  : key == "withdraw" && "WalletTabsActive-light"
              }
              eventKey="withdraw"
              title={<span>WITHDRAW</span>}
            >
              {/* {coin?.length > 0 ? ( */}
              <WithdrawTab coin={coin} hidemodal={props.onHide} />
              {/* ) : (
                <></>
              )} */}
            </Tab>
          </Tabs>
        </div>
      </Modal.Header>

      <Modal.Footer>
        <Button onClick={props.onHide} bsPrefix="close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default WalletModal;
