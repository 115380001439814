import React, { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import ProductCarousel from "../../ProductCarousel/ProductCarousel";
import varietybox from "../../../assets/icons/varietybox.png";
import Overlay from "../../../components/overLay/Overlay";
import { boxProducts } from "../../../api/modules/loothoot.class";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../../redux/slices/loaderSlice";
import FullAppLoader from "../../Loader/FullAppLoader";

export const BoxDetail = (state) => {
  const [loading, setLoading] = useState(true);
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const dispatch = useDispatch();
  const location = useLocation();
  // const boxName = location?.state?.boxName?.replace(/\s+/g, "-")?.toLowerCase();
  // const boxPrice = location?.state?.boxPrice;

  const [boxId, setBoxId] = useState();
  const [boxItems, setboxItems] = useState();
  const [spinnerData, setSpinnerData] = useState();
  const [unbox, setUnbox] = useState();
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayData, setoverlayData] = useState();
  const { slugId } = useParams();

  const decryptData = () => {
    try {
      const decodedData = window?.atob(slugId)?.toString();
      return decodedData;
    } catch (error) {
      console.error("Decryption error:", error);
    }
  };
  useEffect(() => {
    const decryptedData = decryptData();

    try {
      dispatch(toggleLoader({ isLoading: true }));

      const api = async () => {
        const response = await boxProducts(decryptedData);
        setBoxId(response?.data?.data?.data[0]?.id);
        await setboxItems(response?.data?.data?.data[0]);
        setLoading(false);
        await setSpinnerData(response?.data?.spinner);
        await setUnbox(response?.data?.data?.data[0]);
        dispatch(toggleLoader({ isLoading: false }));
      };

      api();
    } catch (err) {
      console.log(err);
    }
  }, [slugId]);

  return isLoading ? (
    <FullAppLoader />
  ) : (
    <div>
      {boxItems?.products && spinnerData ? (
        <>
          <Overlay
            setShowOverlay={setShowOverlay}
            showOverlay={showOverlay}
            overlayData={overlayData}
          />
          <Spinner
            itemsData={boxItems?.products}
            spinnerData={spinnerData}
            unbox={unbox}
            boxPrice={boxItems?.box_price}
            boximage={boxItems?.box_background_images?.background_image}
            boxId={boxId}
          />
          <ProductCarousel
            setShowOverlay={setShowOverlay}
            showOverlay={showOverlay}
            setoverlayData={setoverlayData}
            Boxicon={`${process.env.REACT_APP_API_URI}/public/${boxItems?.box_images?.image}`}
            cardData={{ box: [...boxItems?.products] }}
            name={boxItems?.name}
            description={boxItems?.description}
            productTYpe={"see-more"}
            loading={loading}
            setLoading={setLoading}
            spinner="spinner"
          />
        </>
      ) : (
        <></>
      )}

      {/* <ProductCarousel
        icon={Fire}
        title={"Best Unboxing"}
        subTitle={"Lorem ipsum dolor sit amet consectetur adipisicing elit."}
        cardData={data}
      />
      <ProductCarousel
        icon={Recent}
        title={"Recently Unboxed"}
        subTitle={"Lorem ipsum dolor sit amet consectetur adipisicing elit."}
        cardData={data}
      /> */}
    </div>
  );
};
