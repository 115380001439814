import React, { useState } from "react";
import styles from "./styles.module.scss";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const TextInput = ({
  label,
  isDarkMode,
  name,
  type,
  placeholder,
  value,
  onChnage,
  customClass,
  textarea,
  compolsory,
  height,
  padding,
  ...props
}) => {
  const [isClick, setIsClick] = useState(false);
  const REGEX = /^[+][(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]$/g;
  return (
    <div
      className={`${isDarkMode ? styles.formInputDark : styles.formInput} ${
        customClass ? customClass : " "
      } `}
      {...props}
    >
      <label>
        {label}
        {compolsory && (
          <span
            style={{
              color: "red",
              textDecoration: "none",
              position: "relative",
              display: "initial",
            }}
          >
            *
          </span>
        )}
      </label>
      {textarea ? (
        <textarea
          type={isClick ? "text" : type}
          name={name}
          min={type === "number " ? "0" : ""}
          placeholder={placeholder}
          value={value}
          {...props}
        ></textarea>
      ) : (
        <input
          type={isClick ? "text" : type}
          name={name}
          placeholder={placeholder}
          value={value}
          {...props}
          style={{ paddingLeft: padding }}
          // onChnage={onChnage}
        />
      )}
      {type === "password" && (
        <div className={styles.showPass} onClick={() => setIsClick(!isClick)}>
          {isClick ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </div>
      )}
    </div>
  );
};

export default TextInput;
