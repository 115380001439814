import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./withdraw.css";
import WithdrawCard from "./WithdrawCard";
import WithdrawNext from "./WithdrawNext";
import { getCart } from "../../../../api/modules/loothoot.class";
import Loading from "../../../Loader/Loading";
const BitCoin = ({ item, hidemodal }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [loading, setLoading] = useState(true);
  const [clickedDiv, setClickedDiv] = useState("");
  const [data, setData] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const userId = useSelector((state) => state?.auth?.user?.id);

  useEffect(() => {
    try {
      const api = async () => {
        const response = await getCart(userId);
        setData(response?.data?.data);
        setLoading(false);
      };
      api();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <h2
            className={
              isDarkMode ? "deposit-heading-dark" : "deposit-heading-light"
            }
          >
            Withdraw {item}
          </h2>
        </div>

        <div className="select-items-div">
          <div className="loader-icon">{loading && <Loading />}</div>
          {clickedDiv === "next" && selectedItems.length >= 1 ? (
            <WithdrawNext
              data={selectedItems}
              setClickedDiv={setClickedDiv}
              coin={item}
              hidemodal={hidemodal}
            />
          ) : (
            <WithdrawCard
              data={data}
              setClickedDiv={setClickedDiv}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BitCoin;
