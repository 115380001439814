import React from "react";
import "./battlenotfound.css";
import { useSelector } from "react-redux";
const PageNotFound = () => {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const handleReloadClick = () => {
    window.location.reload();
  };
  return (
    <>
      <div
        className={
          isDarkMode
            ? "end-battle-detail-dark h-100"
            : "end-battle-detail h-100"
        }
      >
        <h1 className="head">
          <span>404</span>
        </h1>
        <p>Oops! Click Retry button to start battle again</p>
        <button className="btn-outline" onClick={handleReloadClick}>
          Retry
        </button>
      </div>
    </>
  );
};

export default PageNotFound;
