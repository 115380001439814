import React from "react";
import Select from "react-select";
import "./select.css";
import { useSelector } from "react-redux";

const CustomSelect = ({ options, onChange, isMulti, components }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#1e293b" : "#f1f5f9",
      border: isDarkMode
        ? "1px solid #334155 !important"
        : "1px solid #e2e8f0 !important",
      borderRadius: "10px",

      height: "52px",
      position: "relative",
      fontStyle: "normal",
      fontSize: "12px",
      lineHeight: "18px",
      padding: "0px 16px !important",
      letterSpacing: "0.025em",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? isDarkMode
          ? "#1e293b !important"
          : "white "
        : isDarkMode
        ? "#1e293b !important"
        : "white ",
      color: state.isSelected
        ? isDarkMode
          ? "white"
          : "black"
        : isDarkMode
        ? "white"
        : "black ",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: isDarkMode ? "white" : "black",
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkMode ? "#1e293b" : "#f1f5f9",
    }),
  };

  return (
    <div className="select-container">
      <Select
        options={options}
        onChange={onChange}
        styles={customStyles}
        components={components}
        isMulti={isMulti}
        defaultValue={options[0]}
      />
    </div>
  );
};

export default CustomSelect;
