import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./livedrop.css";
import { useSelector } from "react-redux";
import { allBoxes } from "../../api/modules/loothoot.class";
import { routes } from "../../routes";
import Loading from "../Loader/Loading";
import { ChevronRight } from "lucide-react";

const LiveDrop = ({ screen }) => {
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [hoveredItem, setHoveredItem] = useState(null);

  const encryptData = (data) => {
    const base64EncodedData = window.btoa(data.toString()); // Convert the integer to a string and then Base64 encode it
    return base64EncodedData;
  };
  const handleClick = (item) => {
    const boxNameEncrypt = encryptData(item?.slug);

    navigate(`${routes.BOXDETAIL}/${boxNameEncrypt}`);
  };
  useEffect(() => {
    try {
      const api = async () => {
        const allresponse = await allBoxes();
        setData(allresponse?.data?.data);
        setLoading(false);
      };

      api();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  // const handleClick = () => {};
  return (
    <>
      <div
        className={
          isDarkMode ? "livedropMain AppDark" : "livedropMain AppLight"
        }
      >
        {screen !== "mobile" && (
          <div className="bulletList">
            <ul>
              <li className={isDarkMode ? "live-drop-dark" : "live-drop-light"}>
                LIVE DROPS
              </li>
            </ul>
          </div>
        )}

        <div className={screen == "mobile" ? "maincardarea" : "maincard"}>
          <div className="loader-icon">{loading && <Loading />}</div>
          {data?.map((item, index) => (
            <div
              onClick={() => handleClick(item)}
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
              className={
                isDarkMode
                  ? screen == "mobile"
                    ? "live-box-small live-box-dark"
                    : "live-box-dark"
                  : screen == "mobile"
                  ? "live-box-small live-box-light"
                  : "live-box-light"
              }
            >
              <div className="live-card-img">
                <img
                  src={`${process.env.REACT_APP_API_URI}/public/${
                    item?.box_images ? item?.box_images?.image : item?.image
                  }`}
                  alt="Card"
                  className={screen == "mobile" && "box-small"}
                  style={{
                    transform:
                      hoveredItem === index
                        ? "rotate(25deg) translateY(-2px)"
                        : "none",
                    transition: "transform 0.3s ease",
                  }}
                />
              </div>
              <div
                className={isDarkMode ? "live-text-dark" : "live-text-light"}
                style={hoveredItem === index ? { width: "80px" } : {}}
              >
                {hoveredItem === index
                  ? `${item?.name}`
                  : `$${item?.box_price}`}
              </div>
              <div>
                <ChevronRight />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default LiveDrop;
