import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BattleUSer from "./BattleUSer";
import Battleicon from "../../assets/images/Battles/battleicon.png";

const BattleFooter = ({ player1, player2 }) => {
  return (
    <>
      <Container className="px-3 py-3">
        <Row style={{ position: "relative" }}>
          <Col
            md={6}
            className={
              player1?.winner ? "battle-footer" : "battlefooter-looser"
            }
          >
            {player1?.winner && <span className="winner-btn">Winner</span>}
            <BattleUSer player={player1} creator={true} />
          </Col>
          <div className="icon-between-cols">
            <img src={Battleicon} alt="" />
          </div>

          <Col
            md={6}
            className={
              player2?.winner ? "battle-footer" : "battlefooter-looser"
            }
          >
            {player2?.winner && <span className="winner-btn">Winner</span>}
            <BattleUSer player={player2} creator={false} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BattleFooter;
