import React from "react";
import "./stats.css";
import Dashboard from "../../components/myaccountdashboard/Dashboard";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";

const MyAccount = () => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />

      <div className="stats-main-div">
        <div className={isDarkMode ? " stats-card-dark" : " stats-cardlight"}>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              style={{
                border: "none",
                borderRadius: "0px",
                boxShadow: "none",
                height: "18px",
                width: "18px",
              }}
            />
            <span
              style={{
                marginLeft: "8px",
                color: "#8e8e99",
                fontSize: ".8em",
              }}
            >
              Hide My Stats From Other Users
            </span>
          </div>

          <div className="stats-info">
            <img
              src="https://www.hypedrop.com/assets/images/box-dollars.png"
              alt=" "
              className="imgbox"
            />

            <span style={{ color: "#8e8e99", fontSize: "12px" }}>
              Total Unboxed Value
            </span>
            <span style={{ fontWeight: "700", fontSize: "15px" }}>$0.00</span>
          </div>
        </div>
      </div>

      <div className="logout-button-section">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
