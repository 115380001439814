import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./BoxTabs.css";
import star from "../../../assets/icons/Boxtabs/star.png";

import leaf from "../../../assets/icons/Boxtabs/leaf.png";
import { useSelector } from "react-redux";
import CardBox from "../../Card/Card";
import { boxCategory } from "../../../api/modules/loothoot.class";
import Loading from "../../../components/Loader/Loading";
import { useLocation } from "react-router-dom";

const BoxTabs = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const boxCatg = location.state?.boxCategory;

  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [key, setKey] = useState(boxCatg ? boxCatg : "Featured");

  // category
  const [featuredData, setfeaturedData] = useState();
  const [updatedData, setupdatedData] = useState();

  const [hotData, sethotData] = useState();

  useEffect(() => {
    try {
      const api = async () => {
        const response = await boxCategory("Featured");
        setfeaturedData(response?.data?.data?.data[0]);
        setLoading(false);

        const updatedresponse = await boxCategory("Updated");
        setupdatedData(updatedresponse?.data?.data?.data[0]);
        setLoading(false);

        const hotResponse = await boxCategory("Hot");
        sethotData(hotResponse?.data?.data?.data[0]);
        setLoading(false);
      };

      api();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div className="px-4 mt-3">
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        transition={false}
        id="noanim-tab"
        bsPrefix={`mb-3 BoxTabsMain ${
          isDarkMode ? "BoxTabsMain-dark" : "BoxTabsMain-light"
        }`}
      >
        <Tab
          tabClassName={
            isDarkMode && key == "Featured"
              ? "BoxTabsActive-dark"
              : key == "Featured" && "BoxTabsActive-light"
          }
          eventKey="Featured"
          title={
            <span className="BoxTabsText">
              <span
                src={star}
                alt=""
                className={key === "Featured" ? "" : "BoxTabsText-img"}
              >
                &#x2B50;
              </span>
              Featured
            </span>
          }
        >
          <div className="loader-icon">{loading && <Loading />}</div>

          <div className="unBoxFeatured mt-3">
            {featuredData?.box?.map((item, index) => (
              <div className="mb-4">
                <CardBox item={item} index={index} type={featuredData?.name} />
              </div>
            ))}

            {/* <div
              className={
                isDarkMode
                  ? " load-more-div-dark load-more-div"
                  : "load-more-div-light load-more-div"
              }
            >
              <button className="mb-5">
                Load More <img src={loadmore} alt="" />
              </button>
            </div> */}
          </div>
        </Tab>
        <Tab
          tabClassName={
            isDarkMode && key == "Hot"
              ? "BoxTabsActive-dark"
              : key == "Hot" && "BoxTabsActive-light"
          }
          eventKey="Hot"
          title={
            <span className="BoxTabsText">
              <span
                src={star}
                alt=""
                className={key === "Hot" ? "" : "BoxTabsText-img"}
              >
                &#x1F525;
              </span>
              Hot
            </span>
          }
        >
          <div className="loader-icon">{loading && <Loading />}</div>
          <div className="unBoxFeatured mt-3">
            {hotData?.box?.map((item, index) => (
              <div className="mb-4">
                <CardBox item={item} index={index} type={hotData?.name} />
              </div>
            ))}

            {/* <div
              className={
                isDarkMode
                  ? " load-more-div-dark load-more-div"
                  : "load-more-div-light load-more-div"
              }
            >
              <button className="mb-3">
                Load More <img src={loadmore} alt="" />
              </button>
            </div> */}
          </div>
        </Tab>
        <Tab
          tabClassName={
            isDarkMode && key == "Updated"
              ? "BoxTabsActive-dark"
              : key == "Updated" && "BoxTabsActive-light"
          }
          eventKey="Updated"
          title={
            <span className="BoxTabsText">
              <span
                src={leaf}
                alt=""
                className={key === "Updated" ? "" : "BoxTabsText-img"}
              >
                &#x1F514;
              </span>
              Updated
            </span>
          }
        >
          <div className="loader-icon">{loading && <Loading />}</div>

          <div className="unBoxFeatured mt-3">
            {updatedData?.box?.map((item, index) => (
              <div className="mb-4">
                <CardBox item={item} index={index} type={updatedData?.name} />
              </div>
            ))}

            {/* <div
              className={
                isDarkMode
                  ? " load-more-div-dark load-more-div"
                  : "load-more-div-light load-more-div"
              }
            >
              <button className="mb-3">
                Load More <img src={loadmore} alt="" />
              </button>
            </div> */}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default BoxTabs;
