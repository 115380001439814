import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import img1 from "../../assets/images/box.png";
import { AiOutlineLink, AiFillSafetyCertificate } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../routes";
import { Col, Container, Row } from "react-bootstrap";
import BattleUSer from "./BattleUSer";
import Battleicon from "../../assets/images/Battles/battleicon.png";
import battle1 from "../../assets/images/Battles/battle1.png";
import ClipboardButton from "../Clipboard/Clipboard";
import {
  battleCancel,
  battlePlayTrigger,
  joinBattle,
} from "../../api/modules/loothoot.class";
import { toggleOnboard } from "../../redux/slices/onBoardingSlice";

const BattleWait = ({ battle, setJoinPrivate }) => {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const user = useSelector((state) => state?.auth?.user);
  const balance = useSelector((state) => state?.account?.balance);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slugId } = useParams();
  const [topUp, setTopup] = useState(false);
  const currentPath = window.location.href;

  const decryptData = () => {
    try {
      const decodedData = parseInt(window.atob(slugId));
      return decodedData;
    } catch (error) {
      console.error("Decryption error:", error);
    }
  };

  const handleCancel = () => {
    const decryptedData = JSON.stringify(decryptData());
    battleCancel(decryptedData)
      .then((response) => {
        if (response?.data?.data == "Battle Deleted!") {
          navigate(routes?.BATTLES);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleJoin = () => {
    const decryptedData = JSON.stringify(decryptData());
    if (isLoggedIn) {
      if (balance >= battle?.box_detail?.battle_price) {
        joinBattle({ battle_id: decryptedData })
          .then((response) => {
            if (
              response?.data?.success &&
              response?.data?.message == "joined"
            ) {
              battlePlayTrigger({ battle_id: decryptedData }).then(
                (response) => {
                  console.log(response);
                }
              );
              setJoinPrivate(true);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        setTopup(true);
      }
    } else dispatch(toggleOnboard({ login: true }));
  };

  return (
    <>
      <div className={isDarkMode ? "battle-wait-head-dark" : "battle-header"}>
        <div className="battle-header-inner">
          <button
            className="battle-back-btn"
            onClick={() => navigate(routes.BATTLES)}
          >
            Back
          </button>
          <div>
            {user?.id == battle?.user_1?.id ||
            user?.id == battle?.user_2?.id ? (
              <h4>Waiting For Player...</h4>
            ) : (
              <h4>Join as 2nd Player!</h4>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <h6>
              <Link to={routes?.PROVABLYFAIR} className="provablyfair-text">
                <AiFillSafetyCertificate /> Provably Fair
              </Link>
            </h6>
          </div>
        </div>
      </div>
      <div
        className={isDarkMode ? "end-battle-detail-dark" : "end-battle-detail"}
      >
        <span className="cancel-text" onClick={handleCancel}>
          CANCEL DUEL
        </span>
        <div className="d-flex justify-content-center gap-3 flex-wrap mt-1">
          <span className="open-text">
            Opening : {battle?.box_detail?.total_box} Boxes
          </span>
          <span className="open-text">
            Total Cost: ${battle?.box_detail?.battle_price}
          </span>
          <a href={currentPath} target="_blank" className="battlewait-link">
            <AiOutlineLink /> {currentPath?.toUpperCase()}
          </a>
        </div>
        <div className="boxes-in-battle-wait d-flex justify-content-center gap-3 flex-wrap mt-4">
          {battle?.box_detail?.boxes?.map((item, index) => (
            <img
              src={
                item?.box_images?.image
                  ? `${process.env.REACT_APP_API_URI}/public/${item?.box_images?.image}`
                  : img1
              }
              alt=""
              width={50}
            />
          ))}
        </div>
      </div>
      <Container className="mt-3">
        <Row style={{ position: "relative" }}>
          <Col md={6}>
            <div className="Battle-Footer-Section1-main">
              <div className="user-icon">
                <img
                  src={
                    battle?.user_1?.userdetails?.avatar
                      ? `${process.env.REACT_APP_API_URI}/public/${battle?.user_1?.userdetails?.avatar}`
                      : battle1
                  }
                  alt=""
                  srcset=""
                />
              </div>
              <div className="Battle-Footer-Section1-des">
                <h6>
                  {battle?.user_1?.userdetails?.username ||
                    `Avatar-${battle?.user_1?.id}`}
                </h6>
                <span>$0.00</span>
              </div>
            </div>
            <div className="mt-4 mb-3 battle-footer-cards-main">
              {battle?.box_detail?.boxes?.map((item, index) => (
                <div className={isDarkMode ? "rounds-dark" : "rounds-light"}>
                  ROUND {index + 1}
                </div>
              ))}
            </div>
          </Col>
          <div className="icon-between-cols">
            <img src={Battleicon} alt="" />
          </div>
          <Col md={6}>
            <div className="Battle-Footer-Section1-main">
              {user?.id == battle?.user_1?.id ||
              user?.id == battle?.user_2?.id ? (
                <ClipboardButton />
              ) : (
                <button className="playwithbot" onClick={() => handleJoin()}>
                  Join Battle
                </button>
              )}
            </div>
            <div className="mt-4 mb-3 d-flex justify-content-start flex-wrap gap-2">
              {battle?.box_detail?.boxes?.map((item, index) => (
                <div className={isDarkMode ? "rounds-dark" : "rounds-light"}>
                  ROUND {index + 1}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BattleWait;
