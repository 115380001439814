import React, { useState, useEffect } from "react";
import "./flipcountdown.css"; // Import your CSS file for styles

const FlipCountDown = ({ onComplete, height }) => {
  const [count, setCount] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        clearInterval(interval);
        onComplete();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [count, onComplete]);

  return (
    <div className="countdown-container" style={{ height: height }}>
      {count === 0 ? (
        <p className="countdown-number">Flip</p>
      ) : (
        <p className="countdown-number">{count}</p>
      )}
    </div>
  );
};

export default FlipCountDown;
