import React, { useEffect, useState } from "react";
import Loading from "../Loader/Loading";
import { boxCategory } from "../../api/modules/loothoot.class";
import { Col, Row } from "react-bootstrap";
import CardBox from "../Card/Card";

const Deals = () => {
  const [hotData, sethotData] = useState();
  const [loading, setLoading] = useState(true);
  const [numSlidehot, setNumslidehot] = useState();
  useEffect(() => {
    try {
      const api = async () => {
        const hotResponse = await boxCategory("Hot");
        sethotData(hotResponse?.data?.data?.data[0]);
        setLoading(false);
        setNumslidehot(hotResponse?.data?.data?.data[0]?.box?.length);
      };

      api();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);
  return (
    <>
      <div className="loader-icon mt-5">{loading && <Loading />}</div>
      <div className="unBoxFeatured">
        {hotData?.box?.map((item, index) => (
          <div className="mb-4">
            <CardBox item={item} index={index} type={hotData?.name} />
          </div>
        ))}
      </div>
    </>
  );
};

export default Deals;
