import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./topupmodal.css";
import { AiOutlinePlus } from "react-icons/ai";
import { MdOutlineSell } from "react-icons/md";
import { togglePayment } from "../../redux/slices/accountSlice";
const TopUpModal = ({ setTopUpModalShow, show }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const dispatch = useDispatch();
  const handleTopUp = () => {
    setTopUpModalShow(false);
    dispatch(togglePayment({ payment: true }));
  };
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={
        isDarkMode
          ? "modal-body-topup modal-content-dark"
          : "modal-body-topup modal-content-light"
      }
    >
      <h5
        className={
          isDarkMode ? "LowBalanceMsg low-balance-dark" : "LowBalanceMsg "
        }
      >
        Oops, your balance is too low
      </h5>
      <Modal.Body className="modalBodyTopup">
        <button className="topup-button" onClick={handleTopUp}>
          <span>
            <AiOutlinePlus />
            Top Up
          </span>
        </button>
        {/* <button className="sellitems-button">
          <span>
            <MdOutlineSell />
            Sell Items
          </span>
        </button> */}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => setTopUpModalShow(false)}
          className="closemodal-button"
        >
          <span>Close</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default TopUpModal;
