import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/TextInput/TextInput";
import { toggleOnboard } from "../../redux/slices/onBoardingSlice";
import { ForgetPassword } from "../../api/modules/loothoot.class";
import Toaster from "../../components/Toast/Toast";
import { useState } from "react";
const FogetModal = (props) => {
  const [error, setError] = useState({ success: "", error: "" });
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const dispatch = useDispatch();
  const initialValues = {
    email: "",
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const response = await ForgetPassword(values);
      setError((prev) => ({
        ...prev,
        success: response?.data?.message || "",
        error: response?.data?.error?.message || "",
      }));
    } catch (error) {
      console.log(error);
    }
    props.onHide();
    dispatch(toggleOnboard({ login: true }));
  };
  const handleBack = () => {
    props?.onHide();
    dispatch(toggleOnboard({ login: true }));
  };

  return (
    <>
      <Toaster message={error} />
      <Modal
        {...props}
        size="md"
        className={isDarkMode ? "modal-content-dark" : "modal-content-light"}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="loginFieldArea" onSubmit={handleSubmit}>
                <h1
                  className={
                    isDarkMode
                      ? "forgetpassheading-dark forgetpassheading"
                      : "forgetpassheading"
                  }
                >
                  FORGOT PASSWORD
                </h1>

                <div className="loginElement">
                  <TextInput
                    isDarkMode={isDarkMode}
                    type="email"
                    name="email"
                    placeholder="Email address"
                    onChange={handleChange}
                    value={values.email}
                    data-value={values.email}
                  />

                  <span className="error">
                    {errors.email && touched.email && errors.email}
                  </span>
                  <p
                    className={
                      isDarkMode
                        ? "forgetpassdesc-dark forgetpassdesc"
                        : "forgetpassdesc"
                    }
                  >
                    Enter your email and we will send you a link to reset your
                    password
                  </p>
                </div>
                <div className="modal-footer-butttons">
                  <button className="signup-btn">
                    <span>Submit</span>
                  </button>
                  <button
                    onClick={handleBack}
                    type="button"
                    className={
                      isDarkMode
                        ? " login-btn-dark login-btn"
                        : "login-btn-light login-btn"
                    }
                  >
                    Back
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FogetModal;
