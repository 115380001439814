import React, { useState } from "react";

import "./spinner.css";

import whiteCirclesLeft from "../../assets/images/spinner/whiteCirclesLeft.png";
import whiteCirclesRight from "../../assets/images/spinner/whiteCirclesRight.png";
import blackCirclesLeft from "../../assets/images/spinner/blackCirclesLeft.png";
import blackCirclesRight from "../../assets/images/spinner/blackCirclesRight.png";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import WheelComponent from "../WheelPrize/WheelPrize";
import SpinModel from "../../pages/SpinModel/SpinModel";
import { useEffect } from "react";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import SpinWheel from "../WheelPrize/SpinWheel";

const Spinner = ({
  itemsData,
  spinnerData,
  unbox,
  boxPrice,
  boxId,
  boximage,
}) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [showSpin, setShowSpin] = useState(false);
  const [winner, setWinner] = useState({ winnerId: "", unboxId: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader({ isLoading: false }));
  }, []);
  return (
    <>
      <div className={isDarkMode ? " AppDark" : " AppLight"}>
        {/* <div className="circlesSides">
          <div>
            <img
              src={isDarkMode ? blackCirclesLeft : whiteCirclesLeft}
              width={200}
            />
          </div>
          <div
            className={
              isDarkMode
                ? "spinnerHeading spinnerHeadingDark"
                : "spinnerHeading spinnerHeadingLight"
            }
          >
            <h1>Unbox Mystery Box Online</h1>
            <div className={!isDarkMode && "headingShade"}></div>
          </div>
          <div>
            <img
              src={isDarkMode ? blackCirclesRight : whiteCirclesRight}
              width={200}
            />
          </div>
        </div> */}
        <div>
          {/* <SpinWheel
            unbox={unbox}
            itemsData={itemsData}
            spinnerData={spinnerData}
            setShowSpin={setShowSpin}
            showSpin={showSpin}
            setWinner={setWinner}
            boxPrice={boxPrice}
          /> */}
          <WheelComponent
            unbox={unbox}
            itemsData={itemsData}
            spinnerData={spinnerData}
            setShowSpin={setShowSpin}
            showSpin={showSpin}
            setWinner={setWinner}
            boxPrice={boxPrice}
            boximage={boximage}
          />
        </div>
      </div>
      <SpinModel
        unbox={unbox}
        showSpin={showSpin}
        setShowSpin={setShowSpin}
        itemsData={itemsData}
        winner={winner}
        boxId={boxId}
      />
    </>
  );
};

export default Spinner;
