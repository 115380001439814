import React from "react";

const Loading = () => {
  return (
    <>
      <div
        class="spinner-border d-flex justify-content-center align-items-center"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </>
  );
};

export default Loading;
