import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import messageReducer from "./slices/messageSlice";
import themeReducer from "./slices/themeSlice";
import loaderReducer from "./slices/loaderSlice";
import onBoardingReducer from "./slices/onBoardingSlice";
import accountReducer from "./slices/accountSlice";

const reducer = {
  auth: authReducer,
  message: messageReducer,
  theme: themeReducer,
  loader: loaderReducer,
  onboarding: onBoardingReducer,
  account: accountReducer,
};

export const store = configureStore({
  reducer: reducer,
  devTools: true,
});