import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import DepositAddress from "./DepositAddress";
import DepositQR from "./DepositQR";

const DepositTab = ({ coin }) => {
  const [clickedDiv, setClickedDiv] = useState(coin[0] || "");
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const [deposit, setDeposit] = useState();

  const handleDivClick = (divName) => {
    setClickedDiv(divName);
  };

  return (
    <>
      <Container>
        <>
          {!deposit ? (
            <Row>
              <Col md={4} className="hr-withdraw">
                <div className="extra-cards-main">
                  {coin?.map((item, index) => (
                    <button
                      style={{ width: "100px" }}
                      onClick={() => handleDivClick(item)}
                      className="close-btn"
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </Col>
              <Col md={8}>
                <DepositAddress item={clickedDiv} setDeposit={setDeposit} />
              </Col>
            </Row>
          ) : (
            <DepositQR deposit={deposit} />
          )}
        </>
      </Container>
    </>
  );
};

export default DepositTab;
