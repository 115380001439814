import React, { useState } from "react";
import "./overlay.css";
import { AiOutlineClose } from "react-icons/ai";
import Drawer from "react-modern-drawer";
import { useSelector } from "react-redux";
import "react-modern-drawer/dist/index.css";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../routes";
import { useLocation } from "react-router-dom";

const OverlayComponent = ({ showOverlay, setShowOverlay, overlayData }) => {
  const { slugId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const handleOverlayClick = () => {
    setShowOverlay(!showOverlay);
  };
  const encryptData = (data) => {
    const base64EncodedData = window?.btoa(data?.toString()); // Convert the integer to a string and then Base64 encode it
    return base64EncodedData;
  };
  const handleClick = (item, index) => {
    const boxNameEncrypt = encryptData(item?.slug);

    if (location?.pathname === `${routes.BOXDETAIL}/${slugId}`) {
      navigate(`${routes.BOXDETAIL}/${boxNameEncrypt}`);
      handleOverlayClick();
    }
  };
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  return (
    <>
      {showOverlay && (
        <Drawer
          open={showOverlay}
          onClose={handleOverlayClick}
          direction="right"
          duration={1000}
          className={isDarkMode ? "drawer drawer-dark" : "drawer drawer-light"}
        >
          <div
            className={
              isDarkMode
                ? "cross-sign-dark cross-sign"
                : "cross-sign-light cross-sign"
            }
          >
            <AiOutlineClose onClick={handleOverlayClick} />
          </div>
          <div className="overlay-section1">
            <h1 className="overlay-heading">Item details</h1>
            <img
              src={`${process.env.REACT_APP_API_URI}/public/${overlayData?.image}`}
              alt=""
            />
            <div className="overlay-heading">{overlayData?.name}</div>
            <div>{overlayData?.description}</div>
            <hr className="horizontal-row" />
          </div>
          <div className="price-range-div">
            <div className="item-desciption">
              <h6>OUTCOME</h6>
              <span>
                {overlayData?.pivot?.min_limit}-{overlayData?.pivot?.max_limit}
              </span>
            </div>
            <div className="mt-2">
              <h6>CONTAINED IN BOXES</h6>
              {overlayData?.boxes.map((item, index) => (
                <img
                  onClick={() => handleClick(item, index)}
                  index={index}
                  src={`${process.env.REACT_APP_API_URI}/public/${item?.box_images?.image}`}
                  height={"70px"}
                  style={{ cursor: "pointer" }}
                />
              ))}
            </div>
          </div>
          <div className="overlay-desciption">
            <h6>Item Desciption:</h6>
            <div className="item-desciption">
              Price: {`$ ${overlayData?.price}`}
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default OverlayComponent;
