import React, { useEffect, useState } from "react";
import { FaqHtml } from "../../api/modules/loothoot.class";
import Faq from "react-faq-component";
import { Container } from "react-bootstrap";
import "./faq.css";
import FullAppLoader from "../../components/Loader/FullAppLoader";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
const FAQ = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const [faq, setFaq] = useState();
  useEffect(() => {
    dispatch(toggleLoader({ isLoading: true }));
    try {
      FaqHtml()
        .then((response) => {
          setFaq(response?.data?.data);
          dispatch(toggleLoader({ isLoading: false }));
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log("Failed", e);
    }
  }, []);

  return isLoading ? (
    <FullAppLoader />
  ) : (
    <Container className="px-5 py-5">
      <h1 className="text-center p-3">FAQ</h1>
      <Faq
        data={{
          rows: faq?.map((faqItem) => ({
            title: <div dangerouslySetInnerHTML={{ __html: faqItem?.title }} />,
            content: (
              <div dangerouslySetInnerHTML={{ __html: faqItem?.content }} />
            ),
          })),
        }}
      />
    </Container>
  );
};

export default FAQ;
