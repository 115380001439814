import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import CustomSelect from "../../components/Select/Select";
import { Card, Col, Container, Row } from "react-bootstrap";

import Loading from "../../components/Loader/Loading";
import AddBox from "./AddBox";

const MyVerticallyCenteredModal = ({
  setLoading,
  loading,
  setSelected,
  selected,
  handleMinusClick,
  handlePlusClick,
  handleAddToBattleClick,
  onHide,
  show,
  data,
  totalBoxes,
  totalPrice,
}) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const typeOptions = [
    { value: "allboxes", label: "All Boxes" },
    { value: "Featured", label: "Featured" },
    { value: "Hot", label: "Hot" },
    { value: "Updated", label: "Updated" },
  ];

  const handleChange = (selectedOption) => {
    setSelected(selectedOption);
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={isDarkMode ? "modal-content-dark" : "modal-content-light"}
    >
      <Modal.Header closeButton bsPrefix="battlemodal-header">
        <div id="contained-modal-title-vcenter">
          <div className="loginElement p-0">
            <CustomSelect
              options={typeOptions}
              isMulti={false}
              onChange={handleChange}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {selected ? (
          <h1
            className={
              isDarkMode
                ? "text-center textLabelDark"
                : "text-center textLabelLight"
            }
          >
            {selected.label}
          </h1>
        ) : (
          <h1
            className={
              isDarkMode
                ? "text-center textLabelDark"
                : "text-center textLabelLight"
            }
          >
            All Boxes
          </h1>
        )}

        <div className="loader-icon">{loading && <Loading />}</div>
        <Container>
          <Row>
            {data?.map((item, index) => (
              <Col md={6} xs={12} sm={6} lg={4} key={item.id}>
                <AddBox
                  item={item}
                  index={index}
                  isDarkMode={isDarkMode}
                  handleAddToBattleClick={handleAddToBattleClick}
                  handlePlusClick={handlePlusClick}
                  handleMinusClick={handleMinusClick}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Body>
      <div className="battle-modal-footer">
        <div
          className={
            isDarkMode
              ? "battle-footer-description battle-footer-description-dark"
              : "battle-footer-description-light battle-footer-description"
          }
        >
          <span>Total Boxes: {totalBoxes}</span>
          <span>Total Price: ${totalPrice}</span>
        </div>

        <button onClick={onHide} className="modal-done-button">
          <span>Done</span>
        </button>
      </div>
    </Modal>
  );
};
export default MyVerticallyCenteredModal;
