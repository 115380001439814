import React, { useRef, useState, useEffect } from "react";
import DashBoardFooter from "../footer/Footer";
import DashBoardHeader from "../header/Header";
import DashboardRightSidebar from "../rightsidebar/Rightsidebar";
import { Container, Row, Col } from "react-bootstrap";
import LiveDrop from "../../livedrop/LiveDrop";
import useWindowDimensions from "../../../customHooks/useDimension/useDimension";
import FullAppLoader from "../../Loader/FullAppLoader";
import ScrollToTop from "../../../customHooks/ScrollToTop/ScrollToTop";

const Layout = ({ children, type = "plain" }) => {
  const elementRef = useRef(null);
  const { width } = useWindowDimensions();

  if (type == "plain")
    return (
      <>
        {children ? (
          <section>
            <DashBoardHeader />
            {children}
            <DashBoardFooter />
          </section>
        ) : (
          <></>
        )}
      </>
    );
  if (type == "sidebar")
    return (
      <>
        <ScrollToTop />
        {children ? (
          <section>
            <DashBoardHeader />
            <LiveDrop screen={"mobile"} />
            <section className="mainBody">
              <Row className="m-0 LayoutMain">
                {width >= 770 ? (
                  <>
                    <Col
                      ref={elementRef}
                      // lg={9}
                      // md={8}
                      className="p-0 mainbody-section1"
                    >
                      {children}
                    </Col>
                    {/* <Col lg={3} md={4} className="sideBar">
                      <LiveDrop />
                    </Col> */}
                  </>
                ) : (
                  <>{children}</>
                )}
              </Row>
              <DashBoardFooter />
            </section>
          </section>
        ) : (
          <></>
        )}
      </>
    );
};

export default Layout;
