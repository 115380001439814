import React from "react";
import QRCode from "react-qr-code";

const DepositQR = ({ deposit }) => {
  return (
    <div className="d-flex flex-column gap-2">
      <div className="main-deposit-address">
        <h3>Deposit Address</h3>
        <span>{deposit?.pay_address || deposit?.address}</span>
      </div>
      <div>
        <h6>Deposit Amount</h6>
        <span>
          {deposit?.pay_amount || deposit?.transaction?.pay_amount} -{" "}
          {deposit?.pay_currency?.toUpperCase() ||
            deposit?.transaction?.pay_currency?.toUpperCase()}
        </span>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h4>Pay through QR Code</h4>
        <QRCode value={deposit?.pay_address || deposit?.address} size={128} />
      </div>
    </div>
  );
};

export default DepositQR;
