import React, { useEffect, useState } from "react";
import { privacyHtml } from "../../api/modules/loothoot.class";
import FullAppLoader from "../../components/Loader/FullAppLoader";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoader } from "../../redux/slices/loaderSlice";
import { Container } from "react-bootstrap";

const Privacy = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const [value, setValue] = useState();
  useEffect(() => {
    try {
      dispatch(toggleLoader({ isLoading: true }));
      privacyHtml()
        .then((response) => {
          setValue(response?.data?.data);

          dispatch(toggleLoader({ isLoading: false }));
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log("Failed", e);
    }
  }, []);
  return isLoading ? (
    <FullAppLoader />
  ) : (
    <Container className="px-5 py-5">
      <h1 className="text-center p-3">Privacy Payments</h1>
      {value
        ? value?.map((item, index) => (
            <div dangerouslySetInnerHTML={{ __html: item?.description }} />
          ))
        : null}
    </Container>
  );
};

export default Privacy;
