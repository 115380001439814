import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./confirm.css";
import { AiOutlinePlus } from "react-icons/ai";
import { MdOutlineSell } from "react-icons/md";
import { togglePayment } from "../../redux/slices/accountSlice";
import { getCart, sellItem, shipItems } from "../../api/modules/loothoot.class";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
const ConfirmModal = ({
  setModalShow,
  show,
  setError,
  item,
  setData,
  type,
}) => {
  const user = useSelector((state) => state?.auth?.user);
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const navigate = useNavigate();

  const handleSell = async (id) => {
    const product = [id];
    try {
      const response = await sellItem({ product_id: product });
      setError((prev) => ({
        ...prev,
        success: response?.data?.message || "",
        error: response?.data?.error?.message || "",
      }));

      if (response?.data?.success) {
        const response = await getCart(user?.id);
        setData(response?.data?.data);
        setModalShow(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleShip = async (id) => {
    const product = [id];
    try {
      const response = await shipItems({ product_id: product });

      setError((prev) => ({
        ...prev,
        success: response?.data?.message || response?.data?.msg || "",
        error: response?.data?.error?.message || "",
      }));

      setModalShow(false);
      if (response?.data?.success) {
        const response = await getCart(user?.id);
        setData(response?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={
        isDarkMode
          ? "modal-body-topup modal-content-dark"
          : "modal-body-topup modal-content-light"
      }
    >
      <h6
        className={
          isDarkMode ? "LowBalanceMsg low-balance-dark" : "LowBalanceMsg "
        }
      >
        {type === "ship"
          ? user?.address?.id
            ? "Are you sure to ship the product?"
            : "You don't have any shipping address details, Please Proceed to your profile and add shipping details."
          : "Are you sure to sell the product?"}
      </h6>
      <Modal.Body className="modalBodyTopup">
        {type === "ship" ? (
          user?.address?.id ? (
            <>
              <button
                className="sell-button"
                onClick={() => handleShip(item?.id)}
              >
                <span>Yes</span>
              </button>
              <button
                className="sell-button"
                onClick={() => setModalShow(false)}
              >
                <span>No</span>
              </button>
            </>
          ) : (
            <>
              <button
                className="sell-button"
                onClick={() => navigate(routes.ACCOUNT)}
              >
                <span>Go to Profile</span>
              </button>
              <button
                className="sell-button"
                onClick={() => setModalShow(false)}
              >
                <span>Cancel</span>
              </button>
            </>
          )
        ) : (
          <>
            <button
              className="sell-button"
              onClick={() => handleSell(item?.id)}
            >
              <span>Yes</span>
            </button>
            <button className="sell-button" onClick={() => setModalShow(false)}>
              <span>No</span>
            </button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default ConfirmModal;
