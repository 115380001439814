import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ExchangeRate, WithDraw } from "../../../../api/modules/loothoot.class";
import Toaster from "../../../Toast/Toast";
const WithdrawNext = ({ data, setClickedDiv, coin, hidemodal }) => {
  const product_id = [];
  const [address, setAddress] = useState(null);
  const [error, setError] = useState({ success: "", error: "" });
  const totalPrice = data.reduce(
    (total, item) => total + parseFloat(item?.price),
    0
  );
  const [coinAmount, setCoinAmount] = useState(totalPrice);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [rate, setRate] = useState();
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const handleInputChange = (event) => {
    setAddress(event.target.value);
  };
  const handleClick = async () => {
    try {
      data?.map((item, index) => {
        product_id.push(item?.id);
      });

      if (address != null) {
        console.log(product_id, coin, address);
        await WithDraw({
          product_id,
          coin,
          address,
          payment_amount:
            coin == "USDT"
              ? coinAmount
              : convertedAmount == 0
              ? coin?.toUpperCase()
              : convertedAmount?.toFixed(6),
        })
          .then((response) => {
            setError((prev) => ({
              ...prev,
              success:
                response?.data?.message ||
                "Selected Items Withdrawn Succesfully",
              error: response?.data?.error?.message || "",
            }));
          })
          .catch((error) => {
            console.log(error);
          });
        hidemodal();
      } else {
        setError((prev) => ({
          ...prev,
          error: "Enter Correct Wallet Address",
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      const api = async () => {
        const res = await ExchangeRate({
          coin: coin,
        });
        setRate(res?.data?.data);
      };
      api();
    } catch (error) {
      console.log(error);
    }
  }, [coin]);

  useEffect(() => {
    setConvertedAmount(coinAmount / rate);
  }, [coinAmount, rate]);

  const handleCoinAmountChange = (e) => {
    const newCoinAmount = e.target.value;
    setCoinAmount(isNaN(newCoinAmount) ? 0 : newCoinAmount);
  };
  return (
    <>
      <Toaster message={error} />
      <div className="card-main-withdraww">
        {data?.map((item, index) => (
          <Card
            className={
              isDarkMode
                ? "battles-main-dark p-2 border-0"
                : "p-2 battles-main-light border-0"
            }
          >
            <div className=" withdraw-card-head">
              <div className="withdraw-card-img">
                <img
                  variant="top"
                  src={`${process.env.REACT_APP_API_URI}/public/${
                    item?.box_images ? item?.box_images?.image : item?.image
                  }`}
                  alt="Card Image"
                  className="card-img-withdraw"
                />
              </div>
            </div>
            <Card.Body>
              <Card.Title className="withdraw-card-title">
                ${item?.price}
              </Card.Title>
            </Card.Body>
          </Card>
        ))}
      </div>
      <div className="withdraw-bitcoin-footer mt-2">
        <div
          className={
            isDarkMode
              ? "deposit-heading-dark withdraw-bitcoin-footer-div"
              : "deposit-heading-light withdraw-bitcoin-footer-div"
          }
        >
          {data?.length} items selected -Total ${totalPrice}
        </div>
        <Button bsPrefix="withdraw-edit" onClick={() => setClickedDiv("edit")}>
          Edit
        </Button>
      </div>
      <div>
        <h6
          className={
            isDarkMode ? "deposit-heading-dark" : "deposit-heading-light"
          }
        >
          WITHDRAWAL AMOUNT
        </h6>
        <div className="btc-conversion">
          <input
            type="text"
            className="btc-input"
            placeholder={"USDT"}
            value={coinAmount}
            onChange={handleCoinAmountChange}
            disabled
          />
          <input
            type="text"
            className="btc-input"
            placeholder={coin?.toUpperCase()}
            value={
              coin == "USDT"
                ? coinAmount
                : convertedAmount == 0
                ? coin?.toUpperCase()
                : convertedAmount?.toFixed(6)
            }
            disabled
          />
        </div>
      </div>
      <div className="mt-2">
        <h6
          className={
            isDarkMode ? "deposit-heading-dark " : "deposit-heading-light"
          }
        >
          YOUR CRYPTOCURRENCY WITHDRAWAL ADDRESS
        </h6>
        <div className="btc-conversion">
          <input
            type="text"
            placeholder="Enter Wallet Address"
            className="withdraw-input"
            onChange={handleInputChange}
          />
        </div>
        <span>
          We will send this amount to the entered Address. Make sure the
          provided information is correct.
        </span>
      </div>
      <div className="mt-4 d-flex justify-content-center align-items-center">
        <button className="withdraw-next" onClick={handleClick}>
          Withdraw
        </button>
      </div>
    </>
  );
};

export default WithdrawNext;
