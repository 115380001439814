import React, { useEffect } from "react";
import "./CoinFlipper.css";

const FlippingCoin = ({ battle1, battle2, result }) => {
  const flipCoin = () => {
    const coin = document.querySelector(".fliper-coin");
    coin.style.animation = "none";

    setTimeout(() => {
      coin.style.animation = `spin-${result} 3s forwards`;
    }, 100);
  };

  useEffect(() => {
    flipCoin();
  }, []);

  return (
    <div className="fliper-container">
      <div className="fliper-coin" id="coin">
        <div className="fliper-heads">
          <img src={battle1} alt="Heads" />
        </div>
        <div className="fliper-tails">
          <img src={battle2} alt="Tails" />
        </div>
      </div>
      <h5 className="Flip-win">Winner</h5>
    </div>
  );
};

export default FlippingCoin;
