import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextInput from "../../../TextInput/TextInput";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import {
  Deposit,
  ExchangeRate,
  getWalletAddress,
} from "../../../../api/modules/loothoot.class";
import Loading from "../../../Loader/Loading";

const DepositAddress = ({ item, setDeposit }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const [rate, setRate] = useState();
  const [calculatedRate, setCalculatedRate] = useState();
  const [isLoading, setLoading] = useState(false);
  const [usdtValue, setUsdtValue] = useState(10);

  useEffect(() => {
    const getAddress = async () => {
      setLoading(true);
      try {
        const res = await ExchangeRate({
          coin: item,
        });
        console.log("exchange", res?.data?.data);
        if (res?.data?.data) {
          setRate(res?.data?.data);
          const result = usdtValue / res?.data?.data;
          setCalculatedRate(result.toFixed(5));
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    getAddress();

    return () => {
      setRate(null);
      setCalculatedRate(null);
    };
  }, [item]);

  const initialValues = {
    coinAmount: usdtValue || "",
  };

  const DepositSchema = Yup.object().shape({
    coinAmount: Yup.string()
      .required("Amount is required")
      .test("is-number", "Amount is not a number", (value) => {
        if (isNaN(Number(value))) {
          return false;
        }
        return true;
      })
      .test("min-amount", "Amount must be at least 10 USDT", (value) => {
        return Number(value) >= 10;
      }),
  });

  const handleSubmit = async (values) => {
    console.log({
      price_amount: usdtValue,
      price_currency: "USDT",
      pay_amount: calculatedRate,
      pay_currency: item,
    });
    setLoading(true);
    try {
      const res = await Deposit({
        price_amount: usdtValue,
        price_currency: "USDT",
        pay_amount: calculatedRate,
        pay_currency: item,
      });
      console.log("deposit", res);
      if (res?.data?.data?.pay_address) {
        setDeposit(res?.data?.data);
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="depositnewbtn-main">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={DepositSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Form>
              <span style={{ color: isDarkMode ? "white" : "black" }}>
                Amount
              </span>
              <TextInput
                type="text"
                name={"coinAmount"}
                placeholder={"USDT"}
                value={values.coinAmount}
                onChange={(e) => {
                  setFieldValue("coinAmount", e.target.value);
                  setUsdtValue(parseFloat(e.target.value));
                  const result = parseFloat(e.target.value) / rate;
                  setCalculatedRate(result.toFixed(5));
                }}
                customClass={isDarkMode ? "formInputDark" : "formInput"}
              />
              <div className="d-flex flex-column">
                <span className="error">
                  {errors.coinAmount && touched.coinAmount && errors.coinAmount}
                </span>
                <span style={{ color: isDarkMode ? "white" : "black" }}>
                  Approx:{" "}
                  {item == "USDT"
                    ? values.coinAmount || "0.00"
                    : calculatedRate == null || calculatedRate == NaN
                    ? "0.00"
                    : calculatedRate}{" "}
                  {item}
                </span>
              </div>

              <div className="d-flex justify-content-center mt-4">
                <button
                  onClick={handleSubmit}
                  className="depositnew-btn py-2 m-2 d-flex justify-content-center"
                >
                  {isLoading ? <Loading /> : <span className="">Deposit</span>}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DepositAddress;
