import React, { useLayoutEffect, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import VerticalRoulette from "../Roulette/VerticalRoulette";
import { RouletteProcessedData } from "../../common/Roulettle";
import Loading from "../Loader/Loading";

const BattleRoulete = ({
  products,
  boxCount,
  setIteration,
  player1,
  player2,
}) => {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const [start, setStart] = useState(false);
  const [key, setKey] = useState(0);
  const [playerOne, setPlayerOne] = useState();
  const [playerTwo, setPlayerTwo] = useState();

  const handlePrizeDefined_player1 = () => {
    // setStart(false);
  };

  const handlePrizeDefined_player2 = () => {
    // setStart(false);
  };

  const handlePlayerData = async () => {
    try {
      await RouletteProcessedData(products, player1?.user_prodcut?.id).then(
        (data) => {
          setPlayerOne(data);
        }
      );

      await RouletteProcessedData(products, player2?.user_product?.id).then(
        (data) => {
          setPlayerTwo(data);
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handlePlayerData();
    setStart(true);
    setKey((prev) => prev + 1);

    return () => {
      setStart(false);
    };
  }, [boxCount]);

  return (
    <>
      {/* {playerTwo?.list?.length > 0 && playerOne?.list?.length > 0 ? ( */}
      <div
        className={
          isDarkMode ? "main-battle-detail-dark" : "main-battle-detail"
        }
      >
        <div className="mx-2">
          <VerticalRoulette
            data={playerOne}
            handlePrizeDefined={handlePrizeDefined_player1}
            spinTime={3}
            start={true}
            key={key}
          />
        </div>
        <div className="mx-2">
          <VerticalRoulette
            data={playerTwo}
            handlePrizeDefined={handlePrizeDefined_player2}
            spinTime={3}
            start={true}
            key={key}
          />
        </div>
      </div>
      {/* ) : (
        <div className="loader-icon">
          <Loading />
        </div>
      )} */}
    </>
  );
};

export default BattleRoulete;
