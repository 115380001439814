import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./fairness.css";
import Dashboard from "../../components/myaccountdashboard/Dashboard";
import MyaccountList from "../../components/myaccount-lists/MyaccountList";
import TextInput from "../../components/TextInput/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { Formik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { Fairness, PostSeed } from "../../api/modules/loothoot.class";
import Toaster from "../../components/Toast/Toast";

const MyAccount = () => {
  const [error, setError] = useState({ success: "", error: "" });
  const [seed, setSeed] = useState(0);
  const [clientSeedValue, setClientSeedValue] = useState();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  const userid = useSelector((state) => state?.auth?.user?.id);

  useEffect(() => {
    try {
      const api = async () => {
        const res = await Fairness();
        setClientSeedValue(res?.data?.data);
      };
      api();
    } catch (error) {
      console.log(error);
    }
  }, [seed]);

  const handleChange = (event) => {
    setClientSeedValue(event.target.value);
  };

  const handleClick = () => {
    try {
      const res = PostSeed({ client_seed: clientSeedValue });
      res
        .then((response) => {
          if (response?.data?.message == "Client seed updated successfully") {
            setSeed((prev) => prev + 1);
          }
          setError((prev) => ({
            ...prev,
            success:
              response?.data?.message || "Request Submitted Successfully",
            error: response?.data?.error?.message || "",
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {/* <Dashboard /> */}
      <MyaccountList />
      <Toaster message={error} />
      <Container className="mb-5 mt-5">
        <Row>
          <Col>
            <h1 className="heading-h1">Fairness</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={{ marginBottom: "3rem" }}>
              UNBOXCRYPTO employs a simple system for verification of games.
              Server seeds are hashed and shown before a game and the user can
              pick any seed they want.
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            // style={{
            //   borderRight: "1px solid black",
            //   borderColor: "#d8d8e2",
            // }}
            md={8}
          >
            <h5 className="heading-h5">Current Seed pair</h5>
            <Form.Group controlId="formBasicEmail" className="formgroup">
              <Form.Label
                style={{
                  marginBottom: "0px",
                  color: "rgb(129 129 121)",
                }}
              >
                CLIENT SEED*
              </Form.Label>
              <div style={{ display: "flex" }}>
                <Form.Control
                  type="text"
                  placeholder=" Client Seed"
                  value={clientSeedValue?.client_seed}
                  className="formcontrol"
                  style={{ boxShadow: "none", borderRadius: "0px" }}
                  onChange={handleChange}
                />
                <Button
                  style={{
                    backgroundColor: "#759FFA",
                    color: "white",
                    border: "none",
                    borderRadius: "30px",
                    textAlign: "center",
                    width: "94px",
                    cursor: "pointer",
                    padding: "2px  16px 2px 16px",
                    height: "42px",
                    fontWeight: "500",
                    marginLeft: "8px",
                  }}
                  onClick={handleClick}
                >
                  Change
                </Button>
              </div>
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="formgroup">
              <Form.Label
                className="label"
                style={{ marginBottom: "0px", color: "rgb(129 129 121)" }}
              >
                SERVER SEED(HASHED)
              </Form.Label>
              <div style={{ display: "flex" }}>
                <Form.Control
                  type="text"
                  placeholder="Server Seed"
                  value={clientSeedValue?.server_seed}
                  style={{ boxShadow: "none", borderRadius: "0px" }}
                />
                {/* <Button
                  style={{
                    backgroundColor: "#759FFA",
                    color: "white",
                    border: "none",
                    borderRadius: "30px",
                    textAlign: "center",
                    width: "94px",
                    cursor: "pointer",
                    padding: "2px  16px 2px 16px",
                    height: "42px",
                    fontWeight: "500",
                    marginLeft: "8px",
                  }}
                  
                >
                  Reveal
                </Button> */}
              </div>
            </Form.Group>
            {/* <Form.Group controlId="formBasicEmail" className="formgroup">
              <Form.Label
                style={{ marginBottom: "0px", color: "rgb(129 129 121)" }}
              >
                FUTURE SERVER SEED(HASHED)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter input 3"
                style={{
                  boxShadow: "none",
                  borderRadius: "0px",
                  height: "42px",
                }}
              />
            </Form.Group> */}
          </Col>

          {/* <Col>
            <h5 className="heading-h5">Previous Seed pair</h5>

            <Form.Group controlId="formBasicEmail" className="formgroup">
              <Form.Label
                style={{ marginBottom: "0px", color: "rgb(129 129 121)" }}
              >
                CLIENT SEED
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter input 4"
                style={{
                  boxShadow: "none",
                  borderRadius: "0px",
                  height: "42px",
                }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="formgroup">
              <Form.Label
                style={{ marginBottom: "0px", color: "rgb(129 129 121)" }}
              >
                SERVER SEED(HASHED)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter input 5"
                style={{
                  boxShadow: "none",
                  borderRadius: "0px",
                  height: "42px",
                }}
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="formgroup">
              <Form.Label
                style={{ marginBottom: "0px", color: "rgb(129 129 121)" }}
              >
                SERVER SEED(REVEALED)
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter input 6"
                style={{
                  boxShadow: "none",
                  borderRadius: "0px",
                  height: "42px",
                }}
              />
            </Form.Group>
          </Col> */}
        </Row>
      </Container>

      <div className="logout-button-section mt-3 mb-3">
        <button className="logout-button" onClick={handleLogout}>
          <span>log out</span>
        </button>
      </div>
    </>
  );
};

export default MyAccount;
