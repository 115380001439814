import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import "./dropdown.css";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../routes";
import { logout } from "../../redux/slices/authSlice";
import battle1 from "../../assets/images/Battles/battle1.png";

const DropDown = ({ isMobileLogo }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <>
      <Dropdown drop="left">
        <Dropdown.Toggle
          style={{ boxShadow: "none" }}
          className={
            isDarkMode
              ? "custom-toggle custom-toggle-dark "
              : " custom-toggle custom-toggle-light "
          }
        >
          <img
            src={
              user?.avatar
                ? `${process.env.REACT_APP_API_URI}/public/${user?.avatar}`
                : battle1
            }
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => navigate(routes.ACCOUNT)}
            className="custom-item"
          >
            profile
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => navigate(routes.CART)}
            className="custom-item"
          >
            cart
          </Dropdown.Item>
          <Dropdown.Item className="custom-item" onClick={handleLogout}>
            logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default DropDown;
