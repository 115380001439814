import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { routes } from "./routes";
// import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import NotFound from "./components/notFound/NotFound";
import Home from "./pages/home/Home";
import MyAccount from "./pages/myaccount/MyAccount";
import { useSelector } from "react-redux";
import UnBoxing from "./components/Allboxes/UnBoxing";
import Layout from "./components/dashboard/layouts/Layout";
import AllBattle from "./components/All Battles/AllBattle";
import { BoxDetail } from "./components/Allboxes/BoxDetails/BoxDetail";
import Stats from "./pages/stats/Stats.jsx";
import Cart from "./pages/cart/Cart.jsx";
import Fairness from "./pages/Fairness/Fairness.jsx";
import Documents from "./pages/documents/Documents.jsx";
import GameHistory from "./pages/gamehistory/GameHistory.jsx";
import Promocode from "./pages/promocode/PromoCode.jsx";
import QuickUpgrade from "./pages/Quickupgrade/QuickUpgrade.jsx";
import Topups from "./pages/top-ups/Top-Ups.jsx";
import Transactions from "./pages/Transactions/Transactions.jsx";
import ProtectedRoutes from "./ProtectedRoutes";
import CreateBattle from "./pages/createBattle/CreateBattle";
import Contact from "./pages/contact-us/Contact";
import Blog from "./pages/Blog/Blog";
import FAQ from "./pages/Faq/Faq.jsx";
import Terms from "./pages/Terms/Terms";
import Privacy from "./pages/Privacy-Payments/Privacy";
import Provably from "./pages/Provably-Fair/ProvablyFair";
import Cookie from "./pages/Cookie/Cookie";
import AmlPolicy from "./pages/AmlPolicy/AmlPolicy";
import BattleDetail from "./components/BattleDetail/BattleDetail";
import Deals from "./components/Deals/Deals";
import Affiliates from "./components/Affiliates/Affiliates";
import FreeDrop from "./components/FreeDrop/FreeDrop";
import ScrollToTop from "./customHooks/ScrollToTop/ScrollToTop";
import Avatar from "./pages/avatar/Avatar";
import Verified from "./components/Verified/verified";
import ResetPassword from "./components/ResetPassword/ResetPassword";
function App() {
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  // const location = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  return (
    <div
      className={`${isDarkMode ? "App AppDark" : "App Applight"} ${isLoading ? "blur" : ""
        }`}
    >

      <Layout type="sidebar">
        <Routes>
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.UNBOXES} element={<UnBoxing />} />
          <Route path={`${routes.BOXDETAIL}/:slugId`} element={<BoxDetail />} />
          <Route path={routes.BATTLES} element={<AllBattle />} />
          <Route
            path={`${routes.BATTLEDETAIL}/:slugId`}
            element={<BattleDetail />}
          />
          <Route path={routes.CREATEBATTLE} element={<CreateBattle />} />
          <Route path={routes.CONTACTUS} element={<Contact />} />
          <Route path={routes.BLOG} element={<Blog />} />
          <Route path={routes.FAQ} element={<FAQ />} />
          <Route path={routes.TERMS} element={<Terms />} />
          <Route path={routes.PRIVACYPAYMENTS} element={<Privacy />} />
          <Route path={routes.PROVABLYFAIR} element={<Provably />} />
          <Route path={routes.COOKIE} element={<Cookie />} />
          <Route path={routes.AMLPOLICY} element={<AmlPolicy />} />
          <Route path={routes.DEALS} element={<Deals />} />
          <Route path={routes.AFFLIATES} element={<Affiliates />} />
          <Route path={routes.FREEDROP} element={<FreeDrop />} />
          <Route path={routes.VERIFIED} element={<Verified />} />
          <Route path={`${routes.RESET}/:slugId`} element={<ResetPassword />} />

          {isLoggedIn && (
            <>
              <Route path={routes.STATS} element={<Stats />} />
              <Route path={routes.CART} element={<Cart />} />
              <Route path={routes.AVATAR} element={<Avatar />} />
              <Route path={routes.QUICKUPGRADE} element={<QuickUpgrade />} />
              <Route path={routes.FAIRNESS} element={<Fairness />} />
              <Route path={routes.DOCUMENTS} element={<Documents />} />
              <Route path={routes.GAMEHISTORY} element={<GameHistory />} />
              <Route path={routes.TRANSACTIONS} element={<Transactions />} />
              <Route path={routes.TOPUPS} element={<Topups />} />
              <Route path={routes.PROMO} element={<Promocode />} />

              <Route
                path={routes.ACCOUNT}
                element={
                  <ProtectedRoutes>
                    <MyAccount />
                  </ProtectedRoutes>
                }
              />
            </>
          )}
        </Routes>
      </Layout>
    </div>
  );
}
export default App;
