import React, { useEffect, useState } from "react";
import cubeLight from "../../assets/icons/cubeDark.png";
import { spinFree, spinWheel } from "../../api/modules/loothoot.class";
import { TbRotate360 } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import { toggleOnboard } from "../../redux/slices/onBoardingSlice";
import TopUpModal from "../TopupModal/TopUpModal";
import { PackageOpen } from "lucide-react";
import { RefreshCw } from "lucide-react";

const WheelComponent = ({
  showSpin,
  setShowSpin,
  spinnerData,
  itemsData,
  setWinner,
  unbox,
  boxPrice,
  boximage,
}) => {
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const isDarkMode = useSelector((state) => state?.theme?.darkMode);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const [loading, setLoading] = useState(false);
  const [topUp, setTopup] = useState(false);
  const balance = useSelector((state) => state?.account?.balance);
  const dispatch = useDispatch();

  let spinAngleStart = 0;
  var product = {};
  var startAngle = 0;
  var arc = Math.PI / (spinnerData?.length / 2);
  var spinTime = 0;
  var spinTimeTotal = 0;
  var imgRadius = 180;
  let angle = startAngle;
  var spinTimeout = null;

  const newArray = [];
  const repeatTimes = Math.floor(35 / spinnerData.length);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  let option = shuffle(spinnerData);

  option = option?.map((item, index) => {
    var img = new Image();
    img.src = item.image;
    img.id = item.id;
    return img;
  });

  if (option?.length < 20) {
    for (let i = 0; i < repeatTimes; i++) {
      newArray.push(...option);
    }
  } else {
    newArray.push(...option);
  }

  // const remainingElements = 30 % option.length;
  // newArray.push(...option.slice(0, remainingElements));

  const ref = () => document?.getElementById("canvas")?.getContext("2d");

  const drawWheel = () => {
    var outsideRadius = 590;
    var insideRadius = 450;
    arc = Math.PI / (newArray?.length / 2);

    const ctx = ref();
    ctx.clearRect(0, 0, 1200, 1200);

    // Set the background color
    ctx.fillStyle = "#f2f2f2"; // Replace with your desired background color
    ctx.beginPath();
    ctx.arc(600, 600, outsideRadius, 0, 2 * Math.PI, false);
    ctx.arc(600, 600, insideRadius, 2 * Math.PI, 0, true);
    ctx.closePath();
    ctx.fill();

    for (var i = 0; i < newArray?.length; i++) {
      angle = startAngle + i * arc;

      ctx.beginPath();
      ctx.arc(600, 600, outsideRadius, angle, angle + arc, false);
      ctx.arc(600, 600, insideRadius, angle + arc, angle, true);
      ctx.strokeStyle = "black";
      ctx.stroke();
      ctx.save();

      // Calculate the angle and position of the arrow
      var arrowAngle = angle + arc / 2;
      var arrowX = 600 + Math.cos(arrowAngle) * (outsideRadius + 40); // Adjust the distance of arrow from the outer circle
      var arrowY = 600 + Math.sin(arrowAngle) * (outsideRadius + 40); // Adjust the distance of arrow from the outer circle

      ctx.translate(arrowX, arrowY);

      // Rotate the arrow
      ctx.rotate(arrowAngle + Math.PI / 2);

      var img = newArray[i];
      if (typeof img) ctx.drawImage(img, -50, 50, 100, 100);

      ctx.restore();

      //Arrow
      ctx.fillStyle = isDarkMode ? "red" : "black";
      ctx.beginPath();
      ctx.moveTo(600 - 2 - 10, 600 - (outsideRadius + 5));
      ctx.lineTo(600 + 2 - 10, 600 - (outsideRadius + 5));
      ctx.lineTo(600 + 2 - 10, 600 - (outsideRadius - 15)); // Make the arrow taller
      ctx.lineTo(600 + 6 - 10, 600 - (outsideRadius - 15)); // Make the arrow taller
      ctx.lineTo(600 + 0 - 10, 600 - (outsideRadius - 22)); // Make the arrow taller and move it up
      ctx.lineTo(600 - 6 - 10, 600 - (outsideRadius - 15)); // Make the arrow taller
      ctx.lineTo(600 - 2 - 10, 600 - (outsideRadius - 15)); // Make the arrow taller
      ctx.lineTo(600 - 2 - 10, 600 - (outsideRadius + 5));
      ctx.fill();
    }
  };

  const handleSpin = (value) => {
    spin(value);
    spinApi(value);
  };

  const spinApi = async (value) => {
    let response;

    if (value == 3000) {
      response = await spinWheel(unbox?.id);
    }

    if (value == 2500) {
      response = await spinFree(unbox?.id);
    }

    setTimeout(() => {
      spinTime += 50;
      product = response?.data?.data?.product;
    }, 2000);

    setWinner((prevState) => ({
      ...prevState,
      unboxId: response?.data?.data?.unbox_id,
      winnerId: response?.data?.data?.product?.id,
    }));
  };

  const spin = (value) => {
    setLoading(true);
    spinAngleStart = Math.random() * 10 + 10;
    spinTime = 0;
    spinTimeTotal = 4 * value;
    rotateWheel(value);
  };

  const rotateWheel = (value) => {
    var degrees = (startAngle * 180) / Math.PI + 90;
    var arcd = (arc * 180) / Math.PI;
    var index = Math.floor((360 - (degrees % 360)) / arcd);

    spinTime += 30;

    if (newArray[index]?.id == product?.id) {
      stopRotateWheel(value);
      return;
    }

    if (spinTime >= spinTimeTotal && value != 3000) {
      stopRotateWheel(value);
      return;
    }

    var spinAngle =
      spinAngleStart - easeOut(spinTime, 0, spinAngleStart, spinTimeTotal);
    startAngle += (spinAngle * Math.PI) / 180;
    drawWheel();

    spinTimeout = setTimeout(() => rotateWheel(value), 30);
  };

  const stopRotateWheel = (value) => {
    setLoading(false);
    const topAngle = 90;
    const degreesPerSection = 360 / newArray.length;

    var degrees = (startAngle * 180) / Math.PI + 90;
    var arcd = (arc * 180) / Math.PI;
    var index = Math.floor((360 - (degrees % 360)) / arcd);
    const stopProductIndex = newArray.findIndex(
      (item) => item.id == product?.id
    );

    const stopAngle = stopProductIndex * degreesPerSection + topAngle;
    const finalAngle = 360 * newArray.length - startAngle + stopAngle;

    const startTime = Date.now();
    let currentAngle = startAngle;

    const rotateWheel = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;

      // Calculate the angle difference based on elapsed time
      const angleDiff = (finalAngle - currentAngle) * (elapsed / 3000); // 3000ms = 3 seconds

      // Update the current angle
      currentAngle += angleDiff;

      // Draw the wheel at the current angle
      drawWheel(currentAngle);

      if (elapsed < 3000 && currentAngle < finalAngle) {
        // Stop after 3 seconds or when reaching the final angle
        requestAnimationFrame(rotateWheel);
      } else {
        if (value == 3000) setShowSpin(!showSpin);
      }
    };

    rotateWheel();
  };

  const easeOut = (t, b, c, d) => {
    var ts = (t /= d) * t;
    var tc = ts * t;
    return b + c * (tc + -3 * ts + 3 * t);
  };

  useEffect(() => {
    drawWheel();
    spin(100);
  }, [isDarkMode]);

  const handleUnbox = () => {
    if (isLoggedIn) {
      if (parseFloat(balance) >= parseFloat(boxPrice)) {
        handleSpin(3000);
      } else {
        setTopup(true);
      }
    } else {
      dispatch(toggleOnboard({ login: true }));
    }
  };

  return (
    <>
      <div
        className="mainCanvas"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_API_URI}/public/${boximage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <canvas
          id="canvas"
          className="canvas"
          width="1200"
          height="1200"
        ></canvas>
      </div>
      <div className="spinner-button-div">
        <button
          className="spinnerButton"
          onClick={handleUnbox}
          disabled={loading}
        >
          <PackageOpen width={15} />
          <span>Unbox Now | ${boxPrice}</span>
        </button>
        <button
          disabled={loading}
          className={
            isDarkMode
              ? "try-free-btn try-free-btn-dark"
              : "try-free-btn try-free-btn-light"
          }
          onClick={() => handleSpin(2500)}
          style={{ boxShadow: "none" }}
        >
          <RefreshCw width={"15px"} /> Try Free
        </button>
      </div>
      <TopUpModal setTopUpModalShow={setTopup} show={topUp} />
    </>
  );
};
export default WheelComponent;
