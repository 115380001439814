import axios from "../axiosConfig/axios";
import endpoints from "../endpoints/endpoints";
//get boxes based on categories
export const boxCategory = async (category) => {
  try {
    return await axios.get(`${endpoints.boxCategory}/${category}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//get all boxes
export const allBoxes = async () => {
  try {
    return await axios.get(`${endpoints.allBoxes}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//get products inside a box
export const boxProducts = async (boxName) => {
  try {
    return await axios.get(`${endpoints.boxProducts}/${boxName}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//post user detail
export const postUserDetils = async (payload) => {
  try {
    return await axios.post(`${endpoints.postUserDetils}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//get user detail
export const userDetail = async (id) => {
  try {
    return await axios.get(`${endpoints.userDetail}/${id}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const spinWheel = async (id) => {
  try {
    return await axios.get(`${endpoints.spinWheel}/${id}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const spinFree = async (id) => {
  try {
    return await axios.get(`${endpoints.spinFree}/${id}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const addCart = async (payload) => {
  try {
    return await axios.post(`${endpoints.addCart}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getCart = async (id) => {
  try {
    return await axios.get(`${endpoints.getCart}/${id}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//get All Battles
export const getAllBattles = async (payload) => {
  try {
    return await axios.get(`${endpoints.getAllBattles}?results=${payload}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getAllBattlesByUser = async (payload) => {
  try {
    return await axios.get(`${endpoints.getAllBattlesByUser}?results=${payload}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//get  Battles by ID

export const getBattlesById = async (payload) => {
  try {
    return await axios.post(endpoints.getBattlesById, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const battleCancel = async (id) => {
  try {
    return await axios.get(`${endpoints.battleCancel}/${id}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//post battles

export const postBattles = async (payload) => {
  try {
    return await axios.post(`${endpoints.postBattles}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const joinBattle = async (payload) => {
  try {
    return await axios.post(`${endpoints.joinBattle}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const battlePlayTrigger = async (payload) => {
  try {
    return await axios.post(`${endpoints.battlePlayTrigger}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const FlipStatus = async (payload) => {
  try {
    return await axios.post(`${endpoints.FlipStatus}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//post Contacts

export const ContactUs = async (payload) => {
  try {
    return await axios.post(`${endpoints.ContactUs}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//sell items
export const sellItem = async (payload) => {
  try {
    return await axios.post(endpoints.sellItem, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//ship items
export const shipItems = async (payload) => {
  try {
    return await axios.post(endpoints.shipItems, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//get  list of coins

export const getCoins = async () => {
  try {
    return await axios.get(`${endpoints.getCoins}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getWalletAddress = async (payload) => {
  try {
    return await axios.post(`${endpoints.getWalletAddress}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//get  list of socail links

export const FooterLinks = async () => {
  try {
    return await axios.get(`${endpoints.FooterLinks}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//get  list of balace

export const GetBalance = async () => {
  try {
    return await axios.get(`${endpoints.GetBalance}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//withdraw
export const WithDraw = async (payload) => {
  try {
    return await axios.post(`${endpoints.WithDraw}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const Deposit = async (payload) => {
  try {
    return await axios.post(`${endpoints.Deposit}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//Exchange Rate
export const ExchangeRate = async (payload) => {
  try {
    return await axios.post(`${endpoints.ExchangeRate}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const FaqHtml = async () => {
  try {
    return await axios.get(endpoints.faq);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const TermsHtml = async () => {
  try {
    return await axios.get(endpoints.terms);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const privacyHtml = async () => {
  try {
    return await axios.get(endpoints.privacy);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const provablyHtml = async () => {
  try {
    return await axios.get(endpoints.provably);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const cookieHtml = async () => {
  try {
    return await axios.get(endpoints.cookie);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const policyHtml = async () => {
  try {
    return await axios.get(endpoints.policy);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//get transactions
export const GetTransaction = async () => {
  try {
    return await axios.get(`${endpoints.GetTransaction}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//documents upload
export const Document = async (payload) => {
  try {
    return await axios.post(endpoints.Document, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//get clint seed
export const Fairness = async () => {
  try {
    return await axios.get(`${endpoints.Fairness}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//post api to change clint seed
export const PostSeed = async (payload) => {
  try {
    return await axios.post(endpoints.PostSeed, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//get Top up
export const Topup = async () => {
  try {
    return await axios.get(`${endpoints.Topup}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//avatar change
export const Avatar = async (payload) => {
  try {
    return await axios.post(endpoints.Avatar, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//Flipcoin 
export const FlipCoin = async (payload) => {
  try {
    return await axios.post(endpoints.FlipCoin, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//get Footer Logo
export const GetFooterLogo = async () => {
  try {
    return await axios.get(`${endpoints.GetFooterLogo}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//Flipcoin 
export const ForgetPassword = async (payload) => {
  try {
    return await axios.post(endpoints.ForgetPassword, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//get Slider Content
export const SliderHome = async () => {
  try {
    return await axios.get(`${endpoints.Slider}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};
//get Slider Content
export const GetDocument = async () => {
  try {
    return await axios.get(`${endpoints.GetDocument}`);
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const ChangePassword = async (id, payload) => {
  try {
    return await axios.post(`${endpoints.reset}/${id}`, payload);
  } catch (error) {
    return { success: false, message: error.message };
  }
};