import React from "react";
import "./style.css";
import { BiPlayCircle } from "react-icons/bi";
import { TbBoxMultiple } from "react-icons/tb";

const data = [
  {
    icon1: <TbBoxMultiple />,
    icon2: <BiPlayCircle size={"2em"} />,
    price: "$ 400",
    img: "https://pngimg.com/uploads/running_shoes/running_shoes_PNG5796.png",
    img2: "https://cdn.pixabay.com/photo/2013/07/12/18/20/shoes-153310_640.png",
  },
  {
    icon1: <TbBoxMultiple />,
    icon2: <BiPlayCircle size={"2em"} />,
    price: "$ 400",
    img: "https://cdn.pixabay.com/photo/2013/07/12/18/22/t-shirt-153368_640.png",
    img2: "https://cdn.pixabay.com/photo/2014/04/03/10/55/t-shirt-311732_640.png",
  },
  {
    icon1: <TbBoxMultiple />,
    icon2: <BiPlayCircle size={"2em"} />,
    price: "$ 400",
    img: "https://pngimg.com/uploads/running_shoes/running_shoes_PNG5796.png",
    img2: "https://cdn.pixabay.com/photo/2017/01/13/04/56/t-shirt-1976334_640.png",
  },
  {
    icon1: <TbBoxMultiple />,
    icon2: <BiPlayCircle size={"2em"} />,
    price: "$ 400",
    img: "https://cdn.pixabay.com/photo/2013/07/13/13/53/rocking-horse-161736_640.png",
    img2: "https://cdn.pixabay.com/photo/2014/09/15/21/01/teddy-bear-447422_640.png",
  },
  {
    icon1: <TbBoxMultiple />,
    icon2: <BiPlayCircle size={"2em"} />,
    price: "$ 400",
    img: "https://cdn.pixabay.com/photo/2014/12/22/00/02/ticket-576639_640.png",
    img2: "https://cdn.pixabay.com/photo/2013/07/13/12/18/passport-159592_640.png",
  },
  {
    icon1: <TbBoxMultiple />,
    icon2: <BiPlayCircle size={"2em"} />,
    price: "$ 400",
    img: "https://pngimg.com/uploads/running_shoes/running_shoes_PNG5796.png",
    img2: "https://cdn.pixabay.com/photo/2014/04/03/10/55/t-shirt-311732_640.png",
  },
  {
    icon1: <TbBoxMultiple />,
    icon2: <BiPlayCircle size={"2em"} />,
    price: "$ 400",
    img: "https://pngimg.com/uploads/running_shoes/running_shoes_PNG5796.png",
    img2: "https://cdn.pixabay.com/photo/2014/04/03/10/55/t-shirt-311732_640.png",
  },
];

const RightSidebar = () => {
  return (
    <>
      <section className="RightSideBar">
        <div className="fs-24 primary-font">
          <h6 className="main-heading">
            <span className="dot">•</span> Live drops
          </h6>
          {data?.map((item, index) => (
            <div className="livedropdiv">
              <div className="livedropsubdiv">
                <div className="topIcon">{item.icon1}</div>

                <a>
                  <div className="card-img">
                    {/* <img src={item.img} height={70} width={120} /> */}
                    <img
                      height={70}
                      width={120}
                      class="default-image"
                      src={item.img}
                      alt="Default Image"
                    />
                    <img
                      height={70}
                      width={120}
                      class="hover-image"
                      src={item.img2}
                      alt="Hover Image"
                    />
                  </div>
                  <div className="priceandbutton">
                    {item.icon2}
                    <span>{item.price}</span>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default RightSidebar;
