import axios from "axios";
import { Avatar, postUserDetils } from "../api/modules/loothoot.class";
import { setCookie, deleteCookie, getCookie } from "./cookies.service";

const API_URL = process.env.REACT_APP_API_URL;

const register = (payload) => {
  return axios.post(API_URL + "/register", payload);
};

const login = (payload) => {
  return axios.post(API_URL + "/login", payload).then((response) => {
    if (response?.data?.token_key) {
      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      setCookie("token", response.data.token_key, expirationTime);
      setCookie("user", JSON.stringify(response.data.user), expirationTime);
    }

    return response?.data;
  });
};

const logout = () => {
  deleteCookie("token");
  deleteCookie("user");
};

const updateUserAddress = (payload) => {
  return postUserDetils(payload).then((response) => {
    if(response?.data?.data){
      const user = JSON.parse(getCookie("user"))
      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      setCookie("user", JSON.stringify({...user, address: response.data.data[0]}), expirationTime);
    }
    return response;
  });
};

const updateUserAvatar = (payload) => {
  return Avatar(payload).then((response) => {
    if(response?.data?.data){
      const user = JSON.parse(getCookie("user"))
      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      setCookie("user", JSON.stringify({...user, avatar: response.data.data}), expirationTime);
    }
    return response;
  });
};

const authService = {
  register,
  login,
  logout,
  updateUserAddress,
  updateUserAvatar,
};

export default authService;
