import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { useSelector } from "react-redux";
import "./topupmodal.css";
import DepositQR from "../dashboard/header/deposit/DepositQR";

const TopUpModalDeposit = ({ setTopUpModalShow, show, deposit }) => {
  const isDarkMode = useSelector((state) => state.theme.darkMode);

  const handleTopUp = () => {
    setTopUpModalShow(false);
  };
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={
        isDarkMode
          ? "modal-body-topup modal-content-dark"
          : "modal-body-topup modal-content-light"
      }
    >
      <h5
        className={
          isDarkMode ? "LowBalanceMsg low-balance-dark" : "LowBalanceMsg "
        }
      >
        Pay Now
      </h5>
      <Modal.Body className="modalBodyTopup">
        <DepositQR deposit={deposit} />
      </Modal.Body>
      <Modal.Footer>
        <button onClick={handleTopUp} className="closemodal-button">
          <span>Close</span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default TopUpModalDeposit;
