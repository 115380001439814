import React from "react";
import { Card } from "react-bootstrap";

const AddBox = ({
  item,
  index,
  isDarkMode,
  handleAddToBattleClick,
  handlePlusClick,
  handleMinusClick,
}) => {
  return (
    <>
      <Card
        bsPrefix={
          isDarkMode
            ? "battle-modal-card addBox-dark mt-3"
            : " battle-modal-card addBox-light mt-3"
        }
      >
        <Card.Body>
          <Card.Img
            className="cardimg"
            variant="top"
            src={`${process.env.REACT_APP_API_URI}/public/${
              item?.box_images ? item?.box_images?.image : item?.image
            }`}
          />

          <Card.Title
            className={
              isDarkMode
                ? "CardTitle cardtext-dark"
                : "CardTitle cardtext-light"
            }
            style={{ marginBottom: "0px" }}
          >
            {item?.name}
          </Card.Title>

          <p
            className={
              isDarkMode ? "CardText cardtext-dark" : "CardText cardtext-light"
            }
          >
            {item?.description}
          </p>

          <p
            className={
              isDarkMode
                ? "battle-item-price-dark battle-item-price"
                : "battle-item-price-light battle-item-price"
            }
          >
            {`$ ${item?.box_price ? item?.box_price : item?.price}`}
          </p>
          {item.quantity > 0 ? (
            <div className=" quantity-controls mb-1">
              <button
                className="minus-icon"
                onClick={() => handleMinusClick(index)}
              >
                -
              </button>
              <span
                className={
                  isDarkMode
                    ? "quantity-text quantity-text-dark"
                    : "quantity-text quantity-text-light"
                }
              >
                {item?.quantity ? item?.quantity : 0}
              </span>
              <button
                className="plus-icon"
                onClick={() => handlePlusClick(index)}
              >
                +
              </button>
            </div>
          ) : (
            <button
              className="add-battle-btn mb-2"
              onClick={() => handleAddToBattleClick(index)}
            >
              <span>Add to Battle</span>
            </button>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default AddBox;
