import React, { useEffect, useState } from "react";
import RoulettePro from "react-roulette-pro";
import "react-roulette-pro/dist/index.css";
import "./VerticalRoulette.css";
import preData from "../../common/data.json";

const VerticalRoulette = ({
  data,
  handlePrizeDefined,
  start,
  key,
  spinTime,
}) => {
  return (
    <RoulettePro
      type="vertical"
      prizes={data?.list ? data?.list : preData}
      prizeIndex={data?.index ? data?.index : Math.floor(preData?.length / 2)}
      start={start}
      spinningTime={spinTime}
      onPrizeDefined={handlePrizeDefined}
      options={{ stopInCenter: true }}
      defaultDesignOptions={{ prizesWithText: true }}
    />
  );
};

export default VerticalRoulette;
